<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>
  <div v-if="dataValue.loading === false" style="padding:15px;margin-bottom: 20px;">
    <div class="divSearch">
      <a-input class="company" placeholder="公司名称" v-model:value="dataValue.request.clientName"/>
      <a-input class="model" placeholder="包括型号" v-model:value="dataValue.request.modelName"/>
      <a-select v-model:value="dataValue.request.quotedPriceTypeName" @change="allEvent.toPage(1)"
                style="width: 105px;margin-right: 20px;">
        <a-select-option value="-1">全部</a-select-option>
        <a-select-option value="0">待回</a-select-option>
        <a-select-option value="1">已回复</a-select-option>
        <a-select-option value="2">放弃</a-select-option>
      </a-select>
      <a-button @click="allEvent.toPage(1)">查询</a-button>
    </div>
    <table class="ahuiTable">
      <tr>
        <th rowspan="2">&nbsp;</th>
        <th rowspan="2">序号</th>
        <th rowspan="2">编号</th>
        <th rowspan="2">公司名</th>
        <th rowspan="2">联系人</th>
        <th rowspan="2">型号数量</th>
        <th rowspan="2">货币类型</th>
        <th rowspan="2">状态</th>
        <th rowspan="2">最新整包报价</th>
        <th rowspan="2">备注</th>
        <th rowspan="2">创建人</th>
        <th rowspan="2">创建时间</th>
        <th rowspan="2">操作</th>
        <th colspan="3" v-if="tfun.check(62)">邮件推广</th>
        <th colspan="2" v-if="tfun.check(61)">短信推广</th>
        <th rowspan="2" v-if="tfun.check(61,62)">推广</th>
      </tr>
      <tr>
        <th v-if="tfun.check(62)">已发</th>
        <th v-if="tfun.check(62)">待发</th>
        <th v-if="tfun.check(62)">待审</th>
        <th v-if="tfun.check(61)">已发</th>
        <th v-if="tfun.check(61)">待发</th>
      </tr>
      <tr v-for="aRow in dataValue.data.list" :style="allEvent.selectRowColor(aRow['batchInBillId'])"
          @click="allEvent.selectRowEvent(aRow['batchInBillId'])">
        <td class="logo"><day_logo :day="aRow['createTime']"/></td>
        <td class="min">{{ aRow['rowNumber'] }}</td>
        <td class="min">{{ aRow['batchInBillCode'] }}</td>
        <td>{{ aRow['companyName'] }}</td>
        <td class="min">{{ aRow['linkmanName'] }}</td>
        <td class="min number">{{ aRow['upSize'] }}</td>
        <td class="min">{{ aRow['moneyTypeName'] }}</td>
        <td class="min">{{ aRow['quotedPriceTypeName'] }}</td>

        <td class="min" v-if="aRow['patchQuotedCount'] > 0">
          <a-popover :title="'共'+ aRow['patchQuotedCount'] +'次整包价：'" placement="topLeft">
            <template #content>
              <table class="ahuiTable2">
                <tr v-for="aPrice in aRow['patchQuotedList']">
                  <td class="price">{{ tools.formatPrice(aPrice['quotedPrice'], 2) }} {{ aPrice['moneyTypeName'] }}</td>
                  <td>{{ aPrice['opEmployeeName'] }}</td>
                  <td>{{ aPrice['createTime'] }}</td>
                </tr>
              </table>
            </template>
            <span class="pop price" type="primary">{{ tools.formatPrice(aRow['patchQuotedList'][0]['quotedPrice'], 2) }} {{
                aRow['patchQuotedList'][0]['moneyTypeName']
              }}</span>
          </a-popover>
        </td>
        <td v-if="aRow['patchQuotedCount'] === 0">&nbsp;</td>
        <td>{{ aRow['remark'] }}</td>
        <td class="min">{{ aRow['opEmployeeName'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min"><a
            @click="allEvent.detailEvent.goOpen(aRow);">明细</a>
        </td>
        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['sendSize'] === undefined || aRow['sendSize'] === 0" class="zero">0</span>
          <span v-if=" aRow['sendSize'] !== undefined && aRow['sendSize'] > 0" class="number">{{aRow['sendSize']}}</span>
        </td>
        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['planSize'] === undefined || aRow['planSize'] === 0" class="zero">0</span>
          <span v-if=" aRow['planSize'] !== undefined && aRow['planSize'] > 0" class="number">
            {{ aRow['planSize'] }}
            <a-popconfirm title="确认要删除所有未发送的记录？" ok-text="是" cancel-text="否" @confirm="allHttp.mail.delPlan(aRow)">
              <label class="goDel">取消</label>
            </a-popconfirm>
          </span>
        </td>

        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['examineSize'] === undefined || aRow['examineSize'] === 0" class="zero">0</span>
          <span v-if=" aRow['examineSize'] !== undefined && aRow['examineSize'] > 0" class="number">
            {{ aRow['examineSize'] }}
            <a-popconfirm title="确认要删除所有未发送的记录？" ok-text="是" cancel-text="否" @confirm="allHttp.mail.delPlan(aRow)">
              <label class="goDel">取消</label>
            </a-popconfirm>
          </span>
        </td>

        <td class="min number" v-if="tfun.check(61)">
          <span v-if=" aRow['smsCount'] === undefined || aRow['smsCount'] === 0" class="zero">0</span>
          <span v-if=" aRow['smsCount'] !== undefined && aRow['smsCount'] > 0" class="number">{{aRow['smsCount']}}</span>
        </td>
        <td class="min number" v-if="tfun.check(61)">
          <span v-if=" aRow['bySendCount'] === undefined || aRow['bySendCount'] === 0" class="zero">0</span>
          <span v-if=" aRow['bySendCount'] !== undefined && aRow['bySendCount'] > 0" class="number">
            {{ aRow['bySendCount'] }}
            <a-popconfirm title="确认要删除所有未发送的记录？" ok-text="是" cancel-text="否" @confirm="allHttp.sms.delPlan(aRow)">
              <label class="goDel">取消</label>
            </a-popconfirm>
          </span>
        </td>
        <td class="min aa" v-if="tfun.check(61,62)">
          <a @click="allEvent.toMail.mailOpen(aRow);" v-if="tfun.check(62)">邮件</a>
          <a @click="allEvent.ic7Event.goOpen(aRow);" v-if="tfun.check(61)">短信</a>
        </td>
      </tr>
      <tr>
        <td colspan="19" class="pages">
          <APages :number_of_page="dataValue.request.aPageCount"
                  :this_page="dataValue.request.thisPage" :all_page="dataValue.data['allPage']"
                  @toPage="allEvent.toPage" :all_count="dataValue.data['allCount']"
          />
        </td>
      </tr>
    </table>
  </div>

  <a-drawer
      :title="dataValue.toIC7.title"
      :width="dataValue.toIC7.width"
      :visible="dataValue.toIC7.visible"
      :body-style="dataValue.toIC7.bodyStyle"
      :footer-style="dataValue.toIC7.footerStyle"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.ic7Event.goClose"
  >
    <ic7Page :row_obj="dataValue.toIC7.rowObj" @goClose="allEvent.ic7Event.goClose()"/>
  </a-drawer>


  <a-drawer
      :title="dataValue.detail.title"
      :width="dataValue.detail.width"
      :visible="dataValue.detail.visible"
      :body-style="dataValue.detail.bodyStyle"
      :footer-style="dataValue.detail.footerStyle"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.detailEvent.goClose" >
    <detailPage :row_obj="dataValue.detail.rowObj"/>
  </a-drawer>


  <a-drawer
      title="群发邮件推广"
      width=950
      :visible="dataValue.toMail.visible"
      placement="right"
      :destroyOnClose="true"
      @close="allEvent.toMail.mailClose" >
    <mainPage :row_obj="dataValue.toMail.rowObj" @goClose="allEvent.toMail.mailClose2" />
  </a-drawer>

</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import tools from "@/tools/small_methods";
import APages from "@/tools/pages"
import ic7Page from "@/view/stock/factory_to_ic7"
import detailPage from "@/view/stock/factory_stock_detail"
import mainPage from "@/view/stock/factory_stock_mail"
import day_logo from "@/components/day_logo";
import tfun from "@/tools/tfun";

export default {
  name : "factory_stock",
  components : { APages, ic7Page, detailPage, day_logo, mainPage },
  emits : [ 'setMenuKey' ],
  setup(props, { emit }) {

    emit('setMenuKey', 'kc.gckc')

    let dataValue = reactive({

      toMail :{
        visible : false,
        rowObj : null
      },

      detail : {
        "title" : "查看库存明细",
        "visible" : false,
        "width" : 1150,
        "rowObj" : null,
        "bodyStyle" : {
          "padding" : "20px 5px 20px 20px",
          "background-color" : "#fbfbf7"
        },
        "footerStyle" : {
          "textAlign" : "right"
        }
      },
      toIC7 : {
        "crmCompanyId" : 5,
        "title" : "通过IC7批量推广",
        "visible" : false,
        "width" : 350,
        "rowObj" : null,
        "bodyStyle" : {
          "padding" : "20px 5px 20px 20px",
          "background-color" : "#fbfbf7"
        },
        "footerStyle" : {
          "textAlign" : "right"
        }
      },
      loading : false,
      request : {
        "modelName" : "",
        "manufacturerName" : "",
        "batchName" : "",
        "clientName" : "",
        "beginTime" : "",
        "endTime" : "",
        "selectType" : 0, // 查询方式 0:模糊 1:精准
        "remark" : "",
        "aPageCount" : 30,
        "thisPage" : 1,
        "batchInBillID" : -1, //421, // 上传的批次， -1表示空
        "quotedPriceTypeName" : "0",
        "quotedPriceType" : 0 // 回复状态: 0:待回 1:已回复 2:拒绝 -1:全部
      },

      data : {
        selectRow : 0,
        allCount : 0,
        allPage : 0,
        list : []
      }

    });



    let allHttp = {

      BatchStock : () => {

        dataValue.loading = true;
        dataValue.request.quotedPriceType = parseInt(dataValue.request.quotedPriceTypeName);
        let dataInput = dataValue.request;
        httpRequest.postEIC('/factory/list.order', dataInput).then((res) => {

          dataValue.loading = false;

          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.data.allCount = res.data.allCount;
            dataValue.data.allPage = res.data.allPage;
            dataValue.data.list = res.data.list;

            dataValue.data.list.forEach((row) => {
              allHttp.sms.rowCount(row);
              allHttp.sms.bySendCount(row);
              allHttp.mail.rowCount(row);
            });
          }
        })

      },

      mail : {

        rowCount : (row) => {

          httpRequest.post('/admin/ic7.mail/send.count', {
            "factoryStockId" : row['batchInBillId'],
            "stockBatchId": 0,
            "userBatchId": 0
          }).then((res) => {
            if (res.code === 200) {
              row['sendSize'] = res.data.sendSize;
              row['planSize'] = res.data.planSize;
              row['examineSize'] = res.data.examineSize;
            }
          })

        },

        delPlan : (row)=>{

          httpRequest.post('/admin/ic7.mail/del.plan', {
            "factoryStockId" : row['batchInBillId'],
            "stockBatchId": 0,
            "userBatchId": 0
          }).then((res) => {
            if (res.code === 200) {
              allHttp.mail.rowCount(row);
            }
          })

        }

      },

      sms : {

        rowCount : (row) => {
          // 获取IC7的报价信息
          httpRequest.post('/admin/ic7/get.send.sms', {
            "factoryStockId" : row['batchInBillId'],
            "stockBatchId": 0,
            "userBatchId": 0,
            "onlyCount" : 1,
            "crmTypes" : [ "1", "2", "3" ]
          }).then((res) => {
            if (res.code === 200) {
              row['smsCount'] = res.data.allCount;
            }
          })
        },

        bySendCount : (row) => {
          // 获取IC7的报价信息
          httpRequest.post('/admin/ic7/plan.count', {
            "factoryStockId" : row['batchInBillId'],
            "stockBatchId": 0,
            "userBatchId": 0
          }).then((res) => {
            if (res.code === 200) {
              row['bySendCount'] = res.data.allCount;
            }
          })
        },

        delPlan : (row) => {
          // 获取IC7的报价信息
          httpRequest.post('/admin/ic7/del.plan', {
            "factoryStockId" : row['batchInBillId'],
            "stockBatchId": 0,
            "userBatchId": 0
          }).then((res) => {
            if (res.code === 200) {
              allHttp.BySendCount(row);
            }
          })
        },

      },


      init : ()=>{

        allHttp.BatchStock();

      }

    }

    allHttp.init();


    let allEvent = {

      toMail : {

        mailOpen : (row)=>{
          dataValue.toMail.visible = true;
          dataValue.toMail.rowObj = row;
        },

        mailClose : ()=>{
          dataValue.toMail.visible = false;
        },

        mailClose2 : (row)=>{
          dataValue.toMail.visible = false;
          allHttp.mail.rowCount(row);
        }

      },

      detailEvent : {

        goOpen : (row) => {
          dataValue.detail.rowObj = row;
          dataValue.detail.title = "来自：" + row['companyName'] + " 的库存明细"
          dataValue.detail.visible = true;
        },

        goClose : () => {
          allHttp.mail.rowCount(dataValue.detail.rowObj);
          dataValue.detail.visible = false;
        }

      },

      ic7Event : {

        goOpen : (row) => {
          dataValue.toIC7.rowObj = row;
          dataValue.toIC7.visible = true;
        },

        goClose : () => {
          allHttp.sms.rowCount(dataValue.toIC7.rowObj);
          dataValue.toIC7.visible = false;
        }

      },

      toPage : (pageId) => {
        dataValue.request.thisPage = pageId;
        allHttp.BatchStock();
      },

      // 选择其中一行
      selectRowEvent : (rowId) => {
        dataValue.data.selectRow = rowId;
      },

      selectRowColor : (rowId) => {
        return dataValue.data.selectRow === rowId ? "background-color:#d8ffd8;" : "";
      }

    }
    
    
    



    return {
      dataValue,
      tools,
      allEvent,
      allHttp,
      tfun
    }

  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
:deep( .ant-modal-body ) {
  padding: 5px;
  height: 100%;
  overflow: auto;
}

.divSearch { margin-bottom: 10px; }
.divSearch .company { width: 300px; margin-right: 20px; }
.divSearch .model { width: 150px; margin-right: 20px; }
.pages { margin: 5px 0 15px 0 !important; line-height: 30px; height: 16px; background-color: #ffffff; }

.goDel{ color: #666666; cursor: pointer; }
.goDel:hover{ color: red; }
.aa a{ margin: 0 2px 0 2px; }
</style>