<template>

  <div class="buttons">

    <div style="float: left; margin: 5px 10px 0 0;cursor: pointer;">
      <div v-if="!dataValue.selectAll" @click="thisEvent.selectAll(true)">
        <border-outlined style="color: #cccccc;"/>
        全部选择
      </div>
      <div v-if="dataValue.selectAll" @click="thisEvent.selectAll(false)">
        <check-outlined style="color: #cccccc;"/>
        全部不选
      </div>
    </div>

    <label>您已经选择了<span class="red">{{ dataValue.checkedCount }}</span>条记录</label>
    <a-button type="primary" v-if="dataValue.checkedCount === 0" disabled>短信推广</a-button>
    <a-button type="primary" v-if="dataValue.checkedCount === 0" disabled style="margin-left: 20px;">批量添加到EC</a-button>
    <a-button type="primary" v-if="dataValue.checkedCount > 0" @click="thisEvent.toIC7.goOpen">短信推广</a-button>
    <a-button type="primary" v-if="dataValue.checkedCount > 0" @click="thisEvent.toEC.sendBatch"
              style="margin-left: 20px;">批量添加到EC
    </a-button>
  </div>

  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在查询数据 ..."></a-spin>

  <table class="ic7Table" v-if="dataValue.supplierInfo.rowCount > 0">
    <tr v-for="aNode in dataValue.supplierInfo.models" @click="thisEvent.clickRow(aNode);">
      <td class="min">{{ aNode['rowIndex'] }}</td>
      <td class="min">
        <check-outlined v-if="aNode.checked" style="color:red;"/>
        <border-outlined v-if="!aNode.checked" style="color: #cccccc;"/>
      </td>
      <td>
        <a-popover :title="aNode['companyName']" placement="topLeft">
          <template #content>
            <p>电话:{{ aNode['tel'] }}</p>
            <p>标记：<label v-html="aNode['labelImg']"></label></p>
            <p>
              <a-button @click="thisEvent.toEC.sendOne(aNode)">把公司信息添加到EC</a-button>
            </p>
          </template>
          <span class="pop" type="primary">{{ aNode['companyName'] }}</span>
        </a-popover>
      </td>
      <td class="min red">{{ aNode['haveSend'] }}</td>
      <td class="min">
        <a-tooltip>
          <template #title>已经成功同步到EC</template>
          <div class="ecTrue" v-if="aNode['EC'] === '成功'">
            <swap-outlined/>
          </div>
        </a-tooltip>
        <a-tooltip>
          <template #title>EC系统里已经有相同的公司名称</template>
          <div class="ecFalse" v-if="aNode['EC'] === '-'">
            <minus-circle-outlined/>
          </div>
        </a-tooltip>
      </td>
      <td>{{ aNode['manufacturer'] }}</td>
      <td>{{ aNode['batch'] }}</td>
      <td class="min number">{{ aNode['number'] }}</td>
    </tr>
  </table>

  <a-drawer
      :title="dataValue.toIC7.title"
      :width="dataValue.toIC7.width"
      :visible="dataValue.toIC7.visible"
      :body-style="dataValue.toIC7.bodyStyle"
      :footer-style="dataValue.toIC7.footerStyle"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="thisEvent.toIC7.goCloseIC7(false)"
  >
    <toIC7Page :factory_stock_id="dataValue.factoryStockId" :models="dataValue.toIC7.models"
               :model_name="dataValue.modelName" :manufacturer_name="dataValue.manufacturerName"
               :batch_name="dataValue.batchName"
               :stock_number="dataValue.stockNumber" @goCloseIC7="thisEvent.toIC7.goCloseIC7"/>
  </a-drawer>

</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import toIC7Page from "@/view/stock/factory_to_ic7_model";

export default {
  name : "factory_stock_detail_get_supplier",
  components : { toIC7Page },
  props : [ 'row_obj', 'factory_stock_id' ],
  emits : [ 'closeSupplier' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      loading : false,
      checkedCount : 0,
      selectAll : false,
      modelName : props.row_obj['modelName'],
      manufacturerName : props.row_obj['manufacturerName'],
      batchName : props.row_obj['batchName'],
      stockNumber : props.row_obj['number'],
      factoryStockId : props['factory_stock_id'],
      supplierInfo : {
        rowCount : 0,
        models : []
      },
      toIC7 : {
        "crmCompanyId" : 5,
        "title" : "通过IC7批量推广",
        "visible" : false,
        "width" : 350,
        "models" : [],
        "bodyStyle" : {
          "padding" : "20px 5px 20px 20px",
          "background-color" : "#fbfbf7"
        },
        "footerStyle" : {
          "textAlign" : "right"
        }
      }
    })


    let thisEvent = {

      toEC : {

        sendBatch : () => {

          dataValue.supplierInfo.models.forEach((node) => {
            if (node['checked']) thisEvent.toEC.sendOne(node);
          });
        },

        sendOne : (node) => {

          let dataInput = {
            "linkModel" : node['model'],
            "companyName" : node['companyName'],
            "address" : node['address'],
            "remark" : node['remark'],
            "qq" : node['qq'],
            "tel" : node['telList'],
            "mobile" : node['mobileList']
          }

          httpRequest.post('/admin/ec/add.company', dataInput)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message, 2);
                } else {

                  let addUserTrueSize = res.data['addUserTrueSize'];
                  let addUserFalseSize = res.data['addUserFalseSize'];
                  let addUserFalseInfo = res.data['addUserFalseInfo'];

                  if (addUserFalseSize === 0) {
                    node['EC'] = "成功"
                    //message.info("添加公司\""+ dataInput.companyName +"\"成功，其中包括" + addUserTrueSize + "个联系人信息",1);
                  } else {
                    node['EC'] = "-"
                    //message.error("添加公司\""+ dataInput.companyName +"\"有" + addUserFalseSize + "条失败记录：" + addUserFalseInfo, 1);
                  }
                }
              })
        }
      },

      toIC7 : {

        goOpen : () => {
          dataValue.toIC7.visible = true;

          // 选择已经选择的记录
          dataValue.toIC7.models = [];
          dataValue.supplierInfo.models.forEach((node) => {
            if (node['checked']) dataValue.toIC7.models.push(node);
          });
        },

        goCloseIC7 : (closeParent) => {
          dataValue.toIC7.visible = false;
          if (closeParent) emit('closeSupplier')
        }

      },

      supplierRequest : () => {
        dataValue.loading = true;
        let dataInput = {
          "modelName" : dataValue.modelName
        }
        httpRequest.post('/admin/ic7/get.model.supplier', dataInput)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message, 2);
                // 错误就关闭
                emit('closeSupplier')
              } else {
                dataValue.supplierInfo = res.data;
              }
              dataValue.loading = false;


              dataValue.supplierInfo['models'].forEach((row) => {

                // 获取IC7的报价信息
                httpRequest.post('/admin/ic7/get.send.sms', {
                  "mobile" : row['tel'],
                  "onlyCount" : 1,
                  "day" : 7,
                  "crmTypes" : [ "3" ]
                }).then((res) => {
                  if (res.code === 200 && res.data.allCount > 0) {
                    row['haveSend'] = "[已推广]";
                  }
                })


              });

            })
      },

      calculateCount : () => {
        let checkTrue = 0, checkFalse = 0;
        dataValue.supplierInfo.models.forEach((node) => {
          if (node['checked']) checkTrue++;
          else checkFalse++;
        });

        dataValue.checkedCount = checkTrue;
        dataValue.selectAll = checkTrue > 0 && checkFalse === 0;
      },

      selectAll : (checkType) => {
        dataValue.supplierInfo.models.forEach((row) => {
          row['checked'] = checkType;
        });
        thisEvent.calculateCount();
      },

      clickRow : (row) => {
        row['checked'] = !row['checked'];
        thisEvent.calculateCount();
      }

    }


    thisEvent.supplierRequest();


    return {
      dataValue,
      thisEvent
    }
  }
}
</script>

<style scoped>
:deep(.ant-modal-body) {
  height: 400px;
  overflow: auto;
}

.ecTrue { color: #225DDC; font-weight: bold; font-size: 14px; }
.ecFalse { color: #bbbbbb; }

.buttons { position: fixed; right: 23px; top: 12px; }
.buttons label { margin: 0 10px 0 10px; }

.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.ic7Table { border-collapse: collapse; width: 100%; border-top: 0.01em solid #cccccc;
  border-spacing: 0; }
.ic7Table tr:nth-child(2n+1) { background-color: #ebefee; }
.ic7Table tr:hover { background-color: #fefebb; cursor: default;}
.ic7Table td { border-bottom: 0.01em solid #cccccc; font-size: 12px; height: 35px; padding: 0 7px 0 7px; }
.ic7Table .number { color: #1a5f91; text-align: center; }
.ic7Table .min { white-space: nowrap; width: 1px; text-align: center; }
.pop { border-bottom: 1px dotted rgb(0, 0, 255); cursor: default; }
.red { color: firebrick; }

</style>