<template>
  <a-spin class="loadingClass" v-if="dataValue.saveing" :tip="dataValue.saveingText"></a-spin>
  <div v-if="!dataValue.saveing">
    <div>
      <div class="aNode">
        <span>型号名称：</span>
        <span>{{ dataValue.modelName }}</span>
      </div>
      <div class="aNode">
        <span>请求数：</span>
        <span>{{ dataValue.models.length }}家</span>
      </div>
      <div class="aNode">
        <span>过期时间(单位小时)：</span>
        <span>
          <a-input style="width:80px;text-align:right;padding-right: 2px;" :max-length="5" type="number"
                   v-model:value="dataValue.endTimeSize"/>
      </span>
      </div>
      <div class="aNode">
        <span>库存数：</span>
        <span>
          <a-input style="width:130px;text-align: right;padding-right: 2px;" :maxlength="10"
                   v-model:value="dataValue.stockNumber" type="number"/>
      </span>
      </div>
      <div class="aNode">
        <span>我的手机号：</span>
        <span>
        <a-input style="width:130px;text-align: right;" v-model:value="dataValue.userInfo['mobile']"/>
      </span>
      </div>
    </div>
    <div class="buttons">
      <a-button type="primary" @click="BatchSend">提交信息-A</a-button>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "factory_to_ic7_model.vue",
  props : [ 'models', 'model_name', 'manufacturer_name', 'batch_name', 'stock_number', 'factory_stock_id' ],
  emits : [ 'goCloseIC7' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      factoryStockId : props.factory_stock_id,
      saveing : false,
      modelName : props.model_name,
      manufacturerName : props.manufacturer_name,
      batchName : props.batch_name,
      models : [],
      endTimeSize : "12",
      stockNumber : props.stock_number.toString(),
      userInfo : {}
    });

    dataValue.models = props.models;

    let UserInfo = {

      get : () => {
        proxy.postEIC('/human/employee/get.info', {})
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.userInfo = res.data;
              }
            })
      },

      setMobile : () => {
        const saveMobile = () => {
          proxy.postEIC('/human/employee/set.mobile', {
            "newMobile" : data.userInfo.mobile
          }).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            }
          })
        }
      }

    }
    UserInfo.get()

    let BatchSend = () => {

      if (parseInt(dataValue.endTimeSize) <= 0) {
        message.error("过期时间必须大于0")
        return
      }

      if (parseInt(dataValue.stockNumber ) <= 0) {
        message.error("库存量必须大于0")
        return
      }

      dataValue.saveing = true;
      dataValue.saveingText = "正在上传数据，请勿关闭窗口 ...";

      let inputData = {
        "factoryStockId" : dataValue.factoryStockId,
        "askUserId" : dataValue.userInfo['employeeId'],
        "askUserName" : dataValue.userInfo['employeeName'],
        "askUserMobile" : dataValue.userInfo['mobile'],
        "askCompany" : "芯链芯",
        "sendType" : 3,

        "modelName" : dataValue.modelName,
        "manufacturerName" : dataValue.manufacturerName,
        "batchName" : dataValue.batchName,
        "number" : parseInt( dataValue.stockNumber ),
        "remark" : "",
        "endTimeSize" : parseInt(dataValue.endTimeSize ),
        "toUserList" : []
      }
      dataValue.models.forEach((row) => {
        inputData.toUserList.push({
          "toCompany": row['companyName'],
          "toUserName": row['userName'],
          "toMobile": row['tel'],
          "toEmail": ""
        });
      });


      proxy.post('/admin/ic7/by.send.to.user', inputData).then((res) => {
        if (res.code !== 200) {
          message.error(res.message);
        } else {
          UserInfo.setMobile();
          message.info("提交成功，请勿重复提交！");
          emit('goCloseIC7', true);
        }

        dataValue.saveing = false;
      })

    }

    return {
      dataValue,
      BatchSend
    }
  }
}
</script>

<style scoped>
.aNode { border-bottom: 1px solid #eeeeee; height: 50px; line-height: 50px; }
.aNode > span:nth-child(2) { float: right; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.buttons { text-align: center;margin-top: 40px; height: 50px }
</style>