<template>
  <a-spin class="loadingClass" v-if="dataValue.saveing" :tip="dataValue.saveingText"></a-spin>
  <div v-if="!dataValue.saveing">
    <div style="padding-right: 20px;">
      <div class="aNode">
        <span>库存来自：</span>
        <span>{{ dataValue.companyName }}</span>
      </div>
      <div class="aNode">
        <span>编号：</span>
        <span>{{ dataValue.batchInBillCode }}</span>
      </div>
      <div class="aNode">
        <span>型号数量：</span>
        <span>{{ dataValue.upSize }}</span>
      </div>
      <div class="aNode">
        <span>总发送量：</span>
        <span>
        <label style="color: #cccccc;">(自动屏蔽7日已发记录)&nbsp;</label>
        <label style="color: red;">{{ dataValue.upSize * dataValue.oneBySend }}</label>
      </span>
      </div>
      <div class="aNode">
        <span>每个型号推广条数：</span>
        <span>
          <a-input v-model:value="dataValue.oneBySend" style="width:80px;text-align:right;padding-right: 2px;"
                   type="number"/>
      </span>
      </div>
      <div class="aNode">
        <span>过期时间(单位小时)：</span>
        <span>
          <a-input v-model:value="dataValue.endTimeSize" style="width:80px;text-align:right;padding-right: 2px;"
                   type="number"/>
      </span>
      </div>
      <div class="aNode">
        <span>我的手机号：</span>
        <span>
        <a-tooltip>
          <template #title>此号码会传递给用户。</template>
          <a-input style="width:130px;text-align: right;" v-model:value="dataValue.userInfo['mobile']"/>
        </a-tooltip>
      </span>
      </div>
    </div>
    <div class="buttons">
      <a-button type="primary" @click="BatchSend">群发推广-B</a-button>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import httpRequest from "@/utils/axios";


export default {
  name : "factory_to_ic7.vue",
  props : [ 'row_obj' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      saveing : false,
      saveingText : "正在加载 ...",
      batchInBillID : props.row_obj['batchInBillId'],
      batchInBillCode : props.row_obj['batchInBillCode'],
      companyName : props.row_obj['companyName'],
      upSize : 0,
      oneBySend : "0",
      endTimeSize : "12",
      userInfo : {},
      modelDetail : []
    });

    // 获取这个bom下所有的记录，用于提交记录
    let getBillDetail = (batchInBillId) => {

      dataValue.saveing = true;
      dataValue.saveingText = "正在加载订单的详细信息...";

      let dataInput = {
        "modelName" : "",
        "manufacturerName" : "",
        "batchName" : "",
        "clientName" : "",
        "beginTime" : "",
        "endTime" : "",
        "selectType" : 0, // 查询方式 0:模糊 1:精准
        "remark" : "",
        "aPageCount" : 999999,
        "thisPage" : 1,
        "batchInBillId" : batchInBillId, //421, // 上传的批次， -1表示空
        "quotedPriceType" : -1 // 回复状态: 0:待回 1:已回复 2:拒绝 -1:全部
      }
      httpRequest.postEIC('/factory/list.detail', dataInput).then((res) => {
        if (res.code !== 200) {
          message.error(res.message);
        } else {
          dataValue.upSize = res.data.allCount;
          dataValue.modelDetail = res.data.list;
        }
        dataValue.saveing = false;
      })
    }
    getBillDetail(props.row_obj['batchInBillId']);


    let UserInfo = {

      get : () => {
        proxy.postEIC('/human/employee/get.info', {})
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.userInfo = res.data;
              }
            })
      },

      setMobile : () => {
        const saveMobile = () => {
          proxy.postEIC('/human/employee/set.mobile', {
            "newMobile" : data.userInfo.mobile
          }).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            }
          })
        }
      }

    }
    UserInfo.get()


    let BatchSend = () => {

      if (parseInt(dataValue.endTimeSize) <= 0) {
        message.error("过期时间必须大于0")
        return
      }

      if (parseInt(dataValue.oneBySend) <= 0) {
        message.error("每个型号的发送数量必须大于0")
        return
      }

      dataValue.saveing = true;
      dataValue.saveingText = "正在保存数据，请勿关闭窗口 ...";

      let inputData = {

        "askUserId" : dataValue.userInfo['employeeId'],
        "askUserName" : dataValue.userInfo['employeeName'],
        "askUserMobile" : dataValue.userInfo['mobile'],
        "askCompany" : "芯链芯",
        "sendType" : 3,
        "eachOfNumber" : parseInt(dataValue.oneBySend),
        "endTimeSize" : parseInt(dataValue.endTimeSize),
        "planSendTime" : "",
        "modelList" : []
      }
      dataValue.modelDetail.forEach((row) => {

        if (parseInt(row['number']) === 0) {
          row['number'] = "999" // 没有数量写成默认的999个
        }

        inputData.modelList.push({
          "factoryStockId" : dataValue.batchInBillID,
          "stockBatchId" : 0,
          "userBatchId" : 0,
          "modelName" : row['modelName'],
          "manufacturerName" : row['manufacturerName'],
          "batchName" : row['batchName'],
          "number" : parseInt(row['number'])
        });
      });

      proxy.post('/admin/ic7/by.plan', inputData).then((res) => {
        if (res.code !== 200) {
          message.error(res.message);
        } else {
          UserInfo.setMobile();
          message.info("将在10分钟后发送" + (dataValue.upSize * dataValue.oneBySend) + "条短信，请勿重复提交！", 8);
          emit('goClose');
        }

        dataValue.saveing = false;
      })
    }


    return {
      dataValue,
      BatchSend
    }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.aNode { border-bottom: 1px solid #eeeeee; line-height: 50px; }
.aNode > span:nth-child(2) { float: right; }
.aNode .spanObj { float: right; }
.buttons { text-align: center;margin-top: 40px; height: 50px }
</style>