<template>
  <diff-two-tone v-if="dataValue.dayLogoType === 1" />
  <copy-two-tone v-if="dataValue.dayLogoType === 2" />
  <copy-outlined v-if="dataValue.dayLogoType === 3" />
</template>

<script>
import {reactive} from "vue";

export default {
  name : "day_logo",
  props : [ 'day' ],
  setup(props, { emits }) {
    let dataValue = reactive({
      day : props.day,
      dayLogoType : 0,
      bDay:0,
      nDay:0
    });

    let bDay = parseInt(dataValue.day.toString().substring(0, 10).replace(/-/g, ''));
    let nDay = parseInt((new Date().getFullYear() * 10000 + ((new Date().getMonth() + 1) * 100) + new Date().getDate()).toString());


    dataValue.bDay = bDay
    dataValue.nDay = nDay

    // 以后
    if (bDay > nDay) {
      dataValue.dayLogoType = 1;
    }

    // 今天
    else if (bDay === nDay) {
      dataValue.dayLogoType = 2;
    }

    // 3天内
    else if (bDay + 3 > nDay) {
      dataValue.dayLogoType = 3;
    }

      return {
        dataValue
      }
    }
  }
</script>

<style scoped>

</style>