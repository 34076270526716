<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>
  <div v-if="dataValue.loading === false" style="padding:5px 15px 5px 5px;">
    <table class="ahuiTable">
      <tr>
        <th>序号</th>
        <th>IC7客户报价</th>
        <th>型号</th>
        <th>品牌</th>
        <th>批号</th>
        <th>封装</th>
        <th>数量</th>
        <th>采购价</th>
        <th>货币</th>
        <th>创建时间</th>
        <th>已推广</th>
        <th>推广</th>
        <th>分析</th>
      </tr>
      <tr v-for="aRow in dataValue.data.list" :style="selectRowColor(aRow['rowNumber'])" @click="selectRowEvent(aRow['rowNumber'])">
        <td class="min">{{ aRow['rowNumber'] }}</td>

        <td class="min zero" v-if="aRow['ic7Price'] === undefined || aRow['ic7Price'].length === 0">-</td>
        <td class="min" :style="tools.priceColor(aRow['ic7Price'][0]['replyMoney'])"
            v-if="aRow['ic7Price'] !== undefined && aRow['ic7Price'].length > 0">

          <a-popover :title="'7天内共有'+ aRow['ic7Price'].length +'家回复报价'" placement="bottomLeft">
            <template #content>
              <table class="ahuiTable2">
                <tr v-for="aPrice in aRow['ic7Price']">
                  <td>{{ aPrice['toCompany'] }} ({{ aPrice['replyMobile'] }})</td>
                  <td class="money" :style="tools.priceColor(aRow['replyMoney'])">{{ aPrice['replyMoneyTypeName'] }}
                    {{ tools.formatPrice(aPrice['replyMoney'], 5) }}
                  </td>
                  <td>{{ aPrice['replyTime'] }}</td>
                </tr>
              </table>
            </template>
            <span type="primary" class="pop">{{
                aRow['ic7Price'][0]['replyMoneyTypeName']
              }} {{ tools.formatPrice(aRow['ic7Price'][0]['replyMoney'], 5) }}</span>
          </a-popover>
        </td>

        <td>{{ aRow['modelName'] }}</td>
        <td>{{ aRow['manufacturerName'] }}</td>
        <td>{{ aRow['batchName'] }}</td>
        <td class="min">{{ aRow['envelopName'] }}</td>
        <td class="min number">{{ aRow['number'] }}</td>
        <td class="min" :style="tools.priceColor(aRow['buyPrice'])">{{ tools.formatPrice(aRow['buyPrice'], 5) }}</td>
        <td class="min">{{ aRow['moneyTypeName'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min number">
          <span v-if=" aRow['smsCount'] === undefined || aRow['smsCount'] === 0" class="zero">0</span>
          <span v-if=" aRow['smsCount'] !== undefined && aRow['smsCount'] > 0" class="number">{{ aRow['smsCount'] }}</span>
        </td>
        <td class="min"><a @click="toSupplierEvent.open(aRow)">推广</a></td>
        <td class="min"><a class="aBQSet" @click="allEvent.bi.open(aRow)">分析</a></td>
      </tr>
    </table>
  </div>


  <a-drawer
      :title="dataValue.toSupplier.title"
      :width="dataValue.toSupplier.width"
      :visible="dataValue.toSupplier.visible"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="toSupplierEvent.closeSupplier"
  >
    <getSupplierInfo :row_obj="dataValue.toSupplier.rowObj" :factory_stock_id="dataValue.factoryStockId" @closeSupplier="toSupplierEvent.closeSupplier" />
  </a-drawer>
  <a-drawer
      :title="dataValue.bi.modelName +'的分析信息'"
      width="1150"
      :visible="dataValue.bi.show"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.bi.close"
  >
    <biModel :model_name="dataValue.bi.modelName"  />
  </a-drawer>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import tools from "@/tools/small_methods"
import getSupplierInfo from "@/view/stock/factory_stock_detail_get_supplier";
import biModel from "@/view/bi/bi_model"

export default {
  name : "factory_stock_detail",
  props : [ "row_obj" ],
  components : { getSupplierInfo, biModel },
  setup(props, { emit }) {

    let dataValue = reactive({
      bi:{
        show: false,
        modelName : ""
      },
      loading : false,
      rowObj : props.row_obj,
      factoryStockId : props.row_obj['batchInBillId'],
      data : {
        selectRow : 0,
        allCount : 0,
        allPage : 0,
        list : []
      },
      toSupplier : {
        "title" : "创建: XXXXXX 的营销推广",
        "visible" : false,
        "width" : 860,
        "rowObj" : null,
      },

      request : {
        "modelName" : "",
        "manufacturerName" : "",
        "batchName" : "",
        "clientName" : "",
        "beginTime" : "",
        "endTime" : "",
        "selectType" : 0, // 查询方式 0:模糊 1:精准
        "remark" : "",
        "aPageCount" : 999999,
        "thisPage" : 1,
        "batchInBillId" : props.row_obj['batchInBillId'], //421, // 上传的批次， -1表示空
        "quotedPriceType" : -1 // 回复状态: 0:待回 1:已回复 2:拒绝 -1:全部
      },
    });


    let toSupplierEvent = {

      open : (row) => {
        dataValue.toSupplier.visible = true;
        dataValue.toSupplier.title = row['modelName']
        dataValue.toSupplier.rowObj = row;
      },

      closeSupplier : ()=>{
        GoEvent.ic7Count(dataValue.toSupplier.rowObj);
        dataValue.toSupplier.visible = false;
      }

    }


    const GoEvent = {

      detailList : ()=>{

        dataValue.loading = true;
        let dataInput = dataValue.request
        httpRequest.postEIC('/factory/list.detail', dataInput).then((res) => {

          dataValue.loading = false;

          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.data.allCount = res.data.allCount;
            dataValue.data.allPage = res.data.allPage;
            dataValue.data.list = res.data.list;

            dataValue.data.list.forEach((row) => {
              GoEvent.ic7Price(row);
              GoEvent.ic7Count(row);
            });
          }
        });
      },

      ic7Price : (row)=>{

        // 获取IC7的报价信息
        httpRequest.post('/admin/ic7/get.reply.price', {
          "modelName" : row['modelName'],
          "day" : 7, // 7天内数据
          "crmTypes" : [ "3" ] // 只要针对工厂销售的
        }).then((res) => {
          if (res.code === 200) {
            row.ic7Price = res.data.list;
          }
        })
      },

      // 7天内请求过的记录
      ic7Count : (row)=>{
        // 获取IC7的报价信息
        httpRequest.post('/admin/ic7/get.send.sms', {
          "modelName" : row['modelName'],
          "onlyCount": 1,
          "day": 7,
          "crmTypes" : [ "3" ]
        }).then((res) => {
          if (res.code === 200) {
            row['smsCount'] = res.data.allCount;
          }
        })
      }

    }

    GoEvent.detailList();


    // 选择其中一行
    const selectRowEvent = (rowId)=>{
      dataValue.data.selectRow = rowId;
    }
    let selectRowColor = (rowId)=>{
      return dataValue.data.selectRow === rowId ? "background-color:#d8ffd8;" : "";
    }


    let allEvent = {

      bi : {
        open : (row)=>{
          dataValue.bi.modelName = row['modelName'];
          dataValue.bi.show = true;
        },
        close: ()=>{
          dataValue.bi.show = false;
        }
      }

    }

    return {
      allEvent,
      dataValue,
      tools,
      toSupplierEvent,
      selectRowEvent,
      selectRowColor
    }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }


</style>